import axiosIns from "@/libs/axios";

const getReporteComprasGenerales = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-compras/compras-generales?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteComprasGeneralesPdf = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-compras/compras-generales-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

const getReporteComprasDetalladas = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-compras/compras-detalladas?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteComprasDetalladasPdf = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-compras/compras-detalladas-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

const getReporteComprasDetalladasPorUsuario = async ({ sedeId, desde, hasta, usuarioId }) => {
  return await axiosIns.get(`reporte-compras/compras-detalladas-por-usuario?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&usuario_id=${usuarioId}`)
}

const getReporteComprasDetalladasPorUsuarioPdf = async ({ sedeId, desde, hasta, usuarioId }) => {
  return await axiosIns.get(`reporte-compras/compras-detalladas-por-usuario-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&usuario_id=${usuarioId}&tipo_respuesta=base64`)
}

const getReporteComprasDetalladasPorProveedor = async ({ sedeId, desde, hasta, clienteProveedorId }) => {
  return await axiosIns.get(`reporte-compras/compras-detalladas-por-proveedor?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&cliente_proveedor_id=${clienteProveedorId}`)
}

const getReporteComprasDetalladasPorProveedorPdf = async ({ sedeId, desde, hasta, clienteProveedorId }) => {
  return await axiosIns.get(`reporte-compras/compras-detalladas-por-proveedor-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&cliente_proveedor_id=${clienteProveedorId}&tipo_respuesta=base64`)
}

const getReporteComprasTotalesMensuales = async ({ sedeId }) => {
  return await axiosIns.get(`reporte-compras/compras-totales-mensuales?sede_id=${sedeId}`)
}

const getReporteComprasTotalesMensualesPdf = async ({ sedeId }) => {
  return await axiosIns.get(`reporte-compras/compras-totales-mensuales-pdf?sede_id=${sedeId}&tipo_respuesta=base64`)
}

export default {
  getReporteComprasGenerales,
  getReporteComprasGeneralesPdf,
  getReporteComprasDetalladas,
  getReporteComprasDetalladasPdf,
  getReporteComprasDetalladasPorUsuario,
  getReporteComprasDetalladasPorUsuarioPdf,
  getReporteComprasDetalladasPorProveedor,
  getReporteComprasDetalladasPorProveedorPdf,
  getReporteComprasTotalesMensuales,
  getReporteComprasTotalesMensualesPdf,
}
