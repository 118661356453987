<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham ag-grid-table mb-1"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :rowData="items"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowClassRules="rowClassRules"
        @first-data-rendered="onFirstDataRendered"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
        :pinnedBottomRowData="pinnedBottomRowData"
      >
      </ag-grid-vue>
    </b-overlay>
    <b-card-text>
      <b-row>
        <b-col cols="6" md="6">
          <span class="text-nowrap text-bold">
            <strong>
              Mostrando
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                items.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : items.length
              }}
              de {{ items.length }} Registros
            </strong>
          </span>
        </b-col>
        <b-col cols="6" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="1"
            align="right"
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon size="18" icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon size="18" icon="ChevronRightIcon" />
            </template>
            <template #last-text>
              <feather-icon size="18" icon="ChevronsRightIcon" />
            </template>
            <template #first-text>
              <feather-icon size="18" icon="ChevronsLeftIcon" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>

<script>
import ListadoReportesMixin from "../mixins/ListadoReportesMixin";

export default {
  name: "ListadoTransaccionesDetalladas",
  mixins: [ListadoReportesMixin],
  data() {
    return {
      columnDefs: [],
    };
  },
  created() {
    this.establecerColumnDefs();
  },
  methods: {
    establecerColumnDefs() {
      const campoCostoOPrecio =
        this.tipoTransaccion === "compra"
          ? [
              {
                headerName: "COSTO",
                field: "costo",
                type: "numericColumn",
                valueFormatter: numberFormatter,
                filter: false,
                minWidth: 150,
                maxWidth: 150,
              },
            ]
          : [
              {
                headerName: "PRECIO",
                field: "precio",
                type: "numericColumn",
                valueFormatter: numberFormatter,
                filter: false,
                minWidth: 150,
                maxWidth: 150,
              },
            ];

      const campoClienteOProveedor =
        this.tipoTransaccion === "compra"
          ? [
              {
                headerName: "PROVEEDOR",
                field: "proveedor",
                filter: false,
                minWidth: 175,
                maxWidth: 175,
              },
            ]
          : [
              {
                headerName: "CLIENTE",
                field: "cliente",
                filter: false,
                minWidth: 175,
                maxWidth: 175,
              },
            ];

      this.columnDefs = [
        {
          headerName: "FECHA",
          field: "fecha",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "HORA",
          field: "hora",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "USUARIO",
          field: "usuario",
          filter: false,
          minWidth: 175,
          maxWidth: 175,
        },
        ...campoClienteOProveedor,
        {
          headerName: "COMPROBANTE",
          field: "comprobante",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "CÓDIGO",
          field: "codigo",
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "MARCA",
          field: "marca",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "PRODUCTO",
          field: "producto",
          filter: false,
          minWidth: 200,
        },
        {
          headerName: "CANTIDAD",
          field: "cantidad",
          type: "numericColumn",
          valueFormatter: numberFormatter,
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        ...campoCostoOPrecio,
        {
          headerName: "DESCUENTO",
          field: "descuento",
          type: "numericColumn",
          valueFormatter: numberFormatter,
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
        {
          headerName: "PROMOCION",
          field: "promocion",
          cellRenderer(param) {
            const { promocion, es_porcentual, valor_descuento } = param.data;

            return `${
              promocion
                ? `<span class="badge badge-primary">${
                    es_porcentual
                      ? `${Number(valor_descuento)}%`
                      : Number(valor_descuento)
                  }</span>`
                : ""
            } ${promocion ? promocion : ""}`;
          },
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "SUBTOTAL",
          field: "subtotal",
          type: "numericColumn",
          valueFormatter: numberFormatter,
          filter: false,
          minWidth: 100,
          maxWidth: 100,
        },
      ];
    },
  },
  computed: {
    pinnedBottomRowData() {
      return [
        {
          fecha: "Totales",
          costo: "",
          precio: "",
          cantidad: "",
          subtotal: this.calcularTotal("subtotal"),
          descuento: this.calcularTotal("descuento"),
          total: this.calcularTotal("total"),
        },
      ];
    },
  },
};

function numberFormatter(params) {
  return Number(params.value).toFixed(2);
}
</script>

<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
</style>
